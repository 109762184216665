import React, { useState, useEffect, useRef} from "react"
import { navigate } from "gatsby"
import { createPortal } from 'react-dom'
import parse from 'html-react-parser'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import manageUserPathHistory from "../../utils/manageUserPathHistory";

import { useProgressContext } from "../../progressWrapper"
import PdfViewer from "../global-components/pdfviewer";
import clickTracking from "../../utils/tracking"
import ArrowLeftIcon from "../../../content/assets/arrow-left-icon.svg"
import KeyIcon from "../../../content/assets/key-icon.svg"

import CaretRight from "../../../content/assets/caret-right-white.svg"
import ArrowRightIcon from "../../../content/assets/arrow-right-icon.svg"
import CaretDownIcon from "../../../content/assets/caret-down-icon.svg"
import CaretUpIcon from "../../../content/assets/caret-up-icon.svg"
import CloseIcon from "../../../content/assets/close-icon-alt.svg"

// Use a ternary operator to make sure that the document object is defined
const portalRoot = typeof document !== `undefined` ? document.body : null

/**
 * SliderDots is intended to be used within pagination section of the ModuleSlider component
 * Accepts the props:
 *   1. slideCount
 *   2. slideIndex
 */

const SliderDots = ({slideCount, slideIndex}) => {
  const dots = []

  for (let i = 0; i < slideCount; i++) {
    dots.push(
      <span
        id={"slider_dot" + i}
        className={
          "slider_dots__dot " +
          (slideIndex === i ? "slider_dots__dot--active" : "")
        }
        key={"slider_dot" + i}
      ></span>
    )
  }

  const content = dots.length > 1 ? <div className="slider_dots">{dots}</div> : null

  return content
}

const wrapEmbed = (element) => {
  const wrapperClass = element.nodeName === 'TABLE' ? 'table-scroll-wrapper' : 'responsive-video-wrapper'
  const wrapper = document.createElement('div');
  wrapper.classList.add(wrapperClass);

  if (!element.parentElement?.classList.contains('responsive-video-wrapper') 
      && !element.parentElement?.classList.contains('embed-container')
      && !element.parentElement?.classList.contains('table-scroll-wrapper')
     ) {
    element.parentNode.insertBefore(wrapper, element);
    wrapper.appendChild(element);
  }
}

const Embed = ({ url }) => {
  return (
    <div className="embed-container">
      <iframe title="Compassus Embed Player" src={url} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
    </div>
  )
}

const Button = ({ button }) => {

  return (
    <a href={button.buttonLink} onClick={clickTracking("Module Button Click", { key: button.buttonLink })} className="module_button">
      <div className="module_button module_button--inside-container" >
        <div className="module_button__header">
          <div className="module_button__header__content">
            <div className="module_button__header__content__text">
              <h3>{button.buttonText}</h3>
            </div>
          </div>
          <div>{<ArrowRightIcon />}</div>
        </div>
      </div>
    </a>
  )
}

const TextBlock = ({text}) => {

  // Removed google docs as href, added download attribute on PDFs

  useEffect(()=>{
    const videosAndTables = document.querySelectorAll('iframe, table')
    if(videosAndTables){
      videosAndTables.forEach(element => wrapEmbed(element))
    }
  }, [])

  //Write some dang gum comments
  return (
    <div className="textblock-container">
      {parse(text, {
        replace: domNode => {
          const link = domNode.attribs ? domNode.attribs.href : null;
          if (link){
            if (link.includes('.pdf')) {
              return <PdfViewer url={link} clickElement={domNode.children[0]}/>
            }
          }
        }
      })}
    </div>
  )
}

const KeyConceptModal = ({modal}) => {

  const [show, setShow] = useState(false)

  const Modal = ({modalContent, activator}) => {
    useEffect(()=>{
      const videosAndTables = document.querySelectorAll('iframe, table')
      if(videosAndTables){
        videosAndTables.forEach(element => wrapEmbed(element))
      }
    }, [])

    const content = show && (
    <div className="modal-container active-modal">
      <div className="modal-container__overlay">
        <div className="modal-container__modal">
          <button
            className="modal-container__close"
            type="button"
            onClick={() => {
              clickTracking("Modal Close Click", { key: modalContent.modalHeader })
              document.body.classList.remove("modal-is-open")
              setTimeout(()=>setShow(false), 300)
            }}
          >
            <CloseIcon />
          </button>
          <div className="modal-container__body">
            <h3>{modalContent.modalHeader}</h3>
            {parse(modalContent.modalBody, {
              replace: domNode => {
                const link = domNode.attribs ? domNode.attribs.href : null;
                if (link){
                  if (link.includes('.pdf')) {
                    return <PdfViewer url={link} clickElement={domNode.children[0]}/>
                  }
                }
              }
            })}
          </div>
        </div>
      </div>
    </div>
    )

    return (
      <>
        {activator({ setShow })}
        {portalRoot ? createPortal(content, document.body) : null}
      </>
    )
  }

  return (
    <div className="modal-container">
      <Modal
        modalContent={modal}
        activator={({ setShow }) => (
          <button
            className="button button--pill"
            type="button"
            onClick={() => {
                setShow(true)
                clickTracking("Modal Open Click", { key: modal.modalButtonHeader })
                document.body.classList.add("modal-is-open")
              }}
          >
            { modal.modalButtonIcon ? <img alt="modal button icon" src={modal.modalButtonIcon.publicUrl} /> : <KeyIcon />}
            <span className="button__icon-text">{modal.modalButtonHeader ? modal.modalButtonHeader : "COMPASSUS KEY CONCEPT"}<CaretRight /></span>
          </button>
        )}
      />
    </div>
  )
}

const Accordion = ({accordion}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(()=>{

    if(isOpen === true) {
      const videosAndTables = document.querySelectorAll('iframe, table')
      if(videosAndTables){
        videosAndTables.forEach(element => wrapEmbed(element))
      }
    }

  }, [isOpen])

  return (
    <div className="accordion accordion--inside-container" >
      <div className="accordion__header" onClick={() => (clickTracking("Single Accordion Click", { key: accordion.accordionHeader }), setIsOpen(!isOpen))}>
        <div className="accordion__header__content">
          <div className="accordion__header__content__text">
            <h3 key="accordion_key_1">{accordion.accordionHeader}</h3>
          </div>
        </div>
        <div>{isOpen ? <CaretUpIcon /> : <CaretDownIcon />}</div>
      </div>
      <TransitionGroup
        component={null}
      >
        {isOpen && (
        <CSSTransition
          in={isOpen}
          timeout={300}
          classNames="accordion-"
        >
          <div className="accordion__body_content">{accordion.accordionBody ? 
            parse(accordion.accordionBody, {
              replace: domNode => {
                const link = domNode.attribs ? domNode.attribs.href : null;
                if (link){
                  if (link.includes('.pdf')) {
                    return <PdfViewer url={link} clickElement={domNode.children[0]}/>
                  }
                }
              }
            })
            : null}
          </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  )
}

const AccordionNested = ({accordion}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(()=>{

    if(isOpen === true) {
      const videosAndTables = document.querySelectorAll('iframe, table')
      if(videosAndTables){
        videosAndTables.forEach(element => wrapEmbed(element))
      }
    }

  }, [isOpen])

  const AccordionSecondNest = ({accordionHeader, accordionBody, accordionRepeater}) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(()=>{

      if(isOpen === true) {
        const videosAndTables = document.querySelectorAll('iframe, table')
        if(videosAndTables){
          videosAndTables.forEach(element => wrapEmbed(element))
        }
      }
  
    }, [isOpen])
  
    return (
      <>
      <div className="accordion accordion--inside-container accordion--nested" >
        <div className="accordion__header" onClick={() => (clickTracking("Nested Accordion Level Two Click", { key: accordionHeader }), setIsOpen(!isOpen))}>
          <div className="accordion__header__content">
            <div className="accordion__header__content__text">
              <h3 key="accordion_key_1">{accordionHeader}</h3>
            </div>
          </div>
          <div>{isOpen ? <CaretUpIcon /> : <CaretDownIcon />}</div>
        </div>
      </div>
      <TransitionGroup
        component={null}
      >
        {isOpen && (
          <CSSTransition
            key={accordionHeader}
            in={isOpen}
            timeout={300}
            classNames="accordion-"
          >
            <div className="accordion__body_content accordion__body_content--nested">
              { accordionBody ? 
                parse(accordionBody, {
                  replace: domNode => {
                    const link = domNode.attribs ? domNode.attribs.href : null;
                    if (link){
                      if (link.includes('.pdf')) {
                        return <PdfViewer url={link} clickElement={domNode.children[0]}/>
                      }
                    }
                  }
                })
                : "" 
              }
              { accordionRepeater ? 
                accordionRepeater.map((accordion, index) => { 
                  return (
                    <AccordionThirdNest 
                      key={index} 
                      accordionHeader={accordion.accordionLevelThreeGroup.accordionLevelThreeHeader} 
                      accordionBody={accordion.accordionLevelThreeGroup.accordionLevelThreeBody} 
                    /> 
                  ) 
                }) 
                : 
                "" 
              }
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      </>
    )
  }

  const AccordionThirdNest = ({accordionHeader, accordionBody}) => {
    const [isOpen, setIsOpen] = useState(false)
  
    useEffect(()=>{

      if(isOpen === true) {
        const videosAndTables = document.querySelectorAll('iframe, table')
        if(videosAndTables){
          videosAndTables.forEach(element => wrapEmbed(element))
        }
      }
  
    }, [isOpen])

    return (
      <>
      <div className="accordion accordion--inside-container accordion--nested" >
        <div className="accordion__header" onClick={() =>(clickTracking("Nested Accordion Level Three Click", { key: accordionHeader }), setIsOpen(!isOpen))}>
          <div className="accordion__header__content">
            <div className="accordion__header__content__text">
              <h3 key="accordion_key_1">{accordionHeader}</h3>
            </div>
          </div>
          <div>{isOpen ? <CaretUpIcon /> : <CaretDownIcon />}</div>
        </div>
      </div>
      <TransitionGroup
          component={null}
      >
        {isOpen && (
          <CSSTransition
            key={accordionHeader}
            in={isOpen}
            timeout={300}
            classNames="accordion-"
          >
            <div className="accordion__body_content accordion__body_content--nested">
              { accordionBody ? 
                parse(accordionBody, {
                  replace: domNode => {
                    const link = domNode.attribs ? domNode.attribs.href : null;
                    if (link){
                      if (link.includes('.pdf')) {
                        return <PdfViewer url={link} clickElement={domNode.children[0]}/>
                      }
                    }
                  }
                })
              : "" }
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      </>
    )
  }

  return (
    <>
    <div className="accordion accordion--inside-container">
      <div className="accordion__header" onClick={() => (clickTracking("Nested Accordion Level One Click", { key: accordion[0].accordionLevelOneHeader }, setIsOpen(!isOpen)))}>
        <div className="accordion__header__content">
          <div className="accordion__header__content__text">
            <h3 key="accordion_key_1">{accordion[0].accordionLevelOneHeader}</h3>
          </div>
        </div>
        <div>{isOpen ? <CaretUpIcon /> : <CaretDownIcon />}</div>
      </div>
    </div>
    <TransitionGroup
      component={null}
    >
      {isOpen && (
        <CSSTransition
          key={accordion[0].accordionLevelOneHeader}
          in={isOpen}
          timeout={300}
          classNames="accordion-"
        >
        <div className="accordion__body_content accordion__body_content--nested">
        { accordion[0].accordionLevelOneBody ? 
          parse(accordion[0].accordionLevelOneBody, {
            replace: domNode => {
              const link = domNode.attribs ? domNode.attribs.href : null;
              if (link){
                if (link.includes('.pdf')) {
                  return <PdfViewer url={link} clickElement={domNode.children[0]}/>
                }
              }
            }
          })
          : "" }
          { accordion[0].accordionLevelTwoRepeater ? accordion[0].accordionLevelTwoRepeater.map((accordion, index) => {
            return (
                <AccordionSecondNest 
                  key={index} 
                  accordionHeader={accordion.accordionLevelTwoGroup.accordionLevelTwoHeader} 
                  accordionBody={accordion.accordionLevelTwoGroup.accordionLevelTwoBody} 
                  accordionRepeater={accordion.accordionLevelTwoGroup.accordionLevelThreeRepeater} 
                />
            )
          })
          :
          "" }
        </div>
        </CSSTransition>
      )}
    </TransitionGroup>
    </>
  )
}

const Image = ({image}) => {
  const stripHtmlFromCaption = (caption) => {
    return caption.replace(/(<([^>]+)>)/gi, "")
  }
  return (
    <div className="image-container">
      <img className="image-container__img" src={image.publicUrl} alt={image.altText}></img>
      <div className="image-container__caption">
       {image.caption ? stripHtmlFromCaption(image.caption) : null}
      </div>
    </div>
  )
}

const Header = ({header}) => {
  return (
    <div className="header-container">
      <h2>{header}</h2>
    </div>
  )
}

const ModuleSlide = ({ slide }) => {

    return (
      <div className="module_slide">
        { slide.map((slide, index) => {
          return (
            slide.header ? <Header key={index} header={slide.header} />
            : slide.textBlock ? <TextBlock key={index} text={slide.textBlock} /> 
            : slide.image ? <Image key={index} image={slide.image} />
            : slide.embed ? <Embed key={index} url={slide.embed} />
            : slide.keyConceptModal ? <KeyConceptModal key={index} modal={slide.keyConceptModal} />
            : slide.accordionSingular ? <Accordion key={index} accordion={slide.accordionSingular} />
            : slide.accordionNested ? <AccordionNested key={index} accordion={slide.accordionNested} />
            : slide.buttonGroup ? <Button key={index} button={slide.buttonGroup} />
            : ""
          )
        })
        }
      </div>
    )
}

const ModuleSlider = ({ databaseId, moduleSlides, parentUri }) => {
  //used to mark current slide, React renders only one container based on slide direction.
  const [current, setCurrent] = useState(0);
  //sets transition boolean to transition when true (part of CSSTransition documentation)
  const [transition, setTransition] = useState(true)
  //Switches between true and false based on direction, conditionally renders transition classNames.
  const [slideDirection, setSlideDirection] = useState(true)
  //used to measure length of slide repeater against current slide. 
  const length = moduleSlides.slideRepeater.length;

  const [progressData, createActivity, updateActivity] = useProgressContext();

  useEffect(()=>{
    createActivity(databaseId, "module")
  },[])

  const completeActivity = () => {
    updateActivity(databaseId, "module")
  }

  //allow slider swiping for mobile devices 
  // const [touchStart, setTouchStart] = useState(0);
  // const [touchEnd, setTouchEnd] = useState(0);

  // const handleTouchStart = (e) => {
  //   setTouchStart(e.targetTouches[0].clientX);
  // }

  // const handleTouchMove = (e) => {
  //     setTouchEnd(e.targetTouches[0].clientX);
  // }

  // const handleTouchEnd = () => {
  //     if ((touchStart - touchEnd > 300) && (current < length -1)) {
  //       nextSlide();
  //     }
  //     if ((touchStart - touchEnd < -300) && (current > 0)) { 
  //       prevSlide();
  //     }
  // }

  //Variable value persists through renders with useRef()
  const componentHasMounted = useRef(false);

  useEffect(() => {
    setSliderHeight()
    
    // update the url with the slide number as user navigates to each slide
    if (componentHasMounted.current) {
      window.history.replaceState(null, null, '?slide-number='+(current + 1));
      manageUserPathHistory();      
    }
    else {
      componentHasMounted.current = true; 
    }
  }, [current]);

  //update the current slide based on "slide-number" param in url
  //used for linking directly to a slide 
  useEffect(() => {
    (() => {
      const params = new URLSearchParams(window.location.search)
      const urlSlideNumber = params.get("slide-number")
  
      if (urlSlideNumber > 0 && urlSlideNumber <= length) {
        setCurrent(parseInt(urlSlideNumber) - 1)
      }
    })()
  }, []);

  //on click event that starts exit transition, sets transition class to right, then renders (transitions in) next slide after timeout.
  const nextSlide = () => {
    setTransition(false)
    setSlideDirection(true)
    setTimeout(() => {
      setCurrent(current === length - 1 ? 0 : current + 1);
      setTransition(true)
    }, 300)
  };

  //on click event that starts exit transition, sets transition class to left, then renders (transitions in) previous slide after timeout.
  const prevSlide = () => {
    setTransition(false)
    setSlideDirection(false)
    setTimeout(() => {
      setCurrent(current === 0 ? length - 1 : current - 1);
      setTransition(true)
    }, 300)
  };

  const setSliderHeight = () => {
    let sliderWrapper = document.querySelector('.module_slider').parentElement;
    let sliderContent = document.querySelector('.module_slider__body_content');
    let imagesOrVideos = document.querySelector('img, video, audio, embed, iframe, .accordion');

    //ensure content has loaded and skip setting height slides that contain elements that take longer to load or change height
    if (!imagesOrVideos){
      setTimeout(() => {
        sliderWrapper.style.height = (sliderContent.offsetHeight+62)+"px"
      }, 50)
    }
    else {
      sliderWrapper.style.height = "auto"
    }
  }

  return (
    <section>
      <div className="content_card">
        <div className="module_slider">
          <div className="module_slider__body_content"
            // onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
            // onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
            // onTouchEnd={() => handleTouchEnd()}
          >
            <div className="module_slider__body_content__inner">
              {/* childFactory allows the transitions to keep track of all elements being mounted and unmounted.  */}
              <TransitionGroup component={null} childFactory={child => React.cloneElement(child)}>
                 { moduleSlides.slideRepeater.map((slide, index) => { 
                  return (
                    <>
                      {index === current && (
                        <CSSTransition
                          in={transition}
                          key={index}
                          timeout={300}
                          classNames={slideDirection ? "module_slide__right-" : "module_slide__left-"}
                        >
                          <ModuleSlide
                            key={index}
                            slide={slide.slide}
                          />
                        </CSSTransition>
                      )}
                    </>
                  )
                  })
                }
              </TransitionGroup>
            </div>
          </div>

          <div className="module_slider__pagination">
            <a
              className={
                "module_slider__pagination__button--prev button button--pill " +
                (current === 0 ? "button--hidden" : "")
              }
              onClick={() => (clickTracking("Previous Module Slide Click", { key: current }), prevSlide())}
            >
              <ArrowLeftIcon />
            </a>

            <SliderDots slideCount={length} slideIndex={current} />
            {current < length - 1 ? (
              <a
                className="module_slider__pagination__button--next button button--pill"
                onClick={() => (clickTracking("Next Module Slide Click", { key: current }), nextSlide())}
              >
                Next
              </a>
              ) : (
              //navigates to previous Summary page.
              <a  className="button button--square"
                    onClick={() => {
                      if(parentUri === undefined || !parentUri) {
                        clickTracking("Module Done Click", { key: "single page" });
                        completeActivity()
                        navigate("/")
                      } else {
                        clickTracking("Module Done Click", { key: parentUri });
                        completeActivity()
                        navigate(parentUri)
                      }
                  }
                }
              >Done</a>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ModuleSlider
