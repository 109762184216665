import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

import Seo from "../components/seo"
import NavBar from "../components/global-components/navbar"
import PathwayHeader from "../components/template-components/pathwayheader"
import ModuleSlider from "../components/template-components/moduleslider"

const ModuleTemplate = ({ data, pageContext }) => {

  const headerColors = data.stepColors.acfOptionsCompassusAppSettings.globalOptions.stepColors;
  const finalColor = {}

  Object.entries(headerColors).map((color) => {
    let stepColor = color[0].replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    if ( pageContext.step ? pageContext.step.replace(/_/g, "-") === stepColor : null ) {
      finalColor["headerColor"] = color[1]
    }
  })

  return (
    console.log("context", pageContext),
    <>
      <Layout>
          <Seo title="Compassus Module Page" />
          <PathwayHeader headerType="module" headerColor={finalColor.headerColor} title={data.module.moduleFields.prettyTitle} pathway={pageContext.parentPathway} step={pageContext.step ? pageContext.step : null} parentUri={pageContext.summaryPath} />
          { data.module.moduleFields.slideRepeater ? 
            <ModuleSlider  
              databaseId={data.module.databaseId} 
              parentUri={pageContext.summaryPath}  
              moduleSlides={data.module.moduleFields}/> 
          : null }
      </Layout>
      <NavBar />
    </>
  )
}

export default ModuleTemplate

//Query for Individual Modules. this query will need to be refined based on authentication. Gets ID through context on gatsby.node page and returns targeted module data.
export const pageQuery = graphql`
  query ModuleById(
    $id: String!
  ) {
    module: wpModule(id: { eq: $id }) {
      parentId
      parentDatabaseId
      moduleSteps {
        nodes {
          slug
        }
      }
      parentPathways {
        nodes {
          slug
        }
      }
      wpParent {
        node {
          id
          uri
          ... on WpPathway {
            id
            title
          }
        }
      }
      ... on WpModule {
        id
        databaseId
        uri
        moduleFields {
          mediaType
          prettyTitle
          slideRepeater {
            slide {
              ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                header
              }
              ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                textBlock
              }
              ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                image {
                  sourceUrl
                  publicUrl
                  altText
                  caption
                  link
                }
              }
              ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                embed
              }
              ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                callout
              }
              ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                keyConceptModal {
                  modalHeader
                  modalBody
                  modalButtonIcon {
                    sourceUrl
                    publicUrl
                    altText
                    caption
                    link
                  }
                  modalButtonHeader
                }
              }
              ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                accordionSingular {
                  accordionHeader
                  accordionBody
                }
              }

              ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                accordionNested {
                  accordionLevelOneHeader
                  accordionLevelOneBody
                  accordionLevelTwoRepeater {
                    accordionLevelTwoGroup {
                      accordionLevelTwoHeader
                      accordionLevelTwoBody
                      accordionLevelThreeRepeater {
                        accordionLevelThreeGroup {
                          accordionLevelThreeHeader
                          accordionLevelThreeBody
                        }
                      }
                    }
                  }
                }
              }
              ... on WpModule_Modulefields_slideRepeater_Slide_Button {
								buttonGroup {
									buttonText
                  buttonLink
                }
              }
            }
          }
        }
      }
    }

    stepColors: wp {
      acfOptionsCompassusAppSettings {
        globalOptions {
          stepColors {
            totalPainManagement
            symptomManagement
            safetyAndAutonomy
            rightMedsRightTime
            qualityOfLife
            overview
            greatestConcern
          }
        }
      }
    }
  }
`
