import React, { useState} from "react"
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { createPortal } from 'react-dom'
import CloseIcon from "../../../content/assets/close-icon-alt.svg"
import '@react-pdf-viewer/core/lib/styles/index.css';

import '@react-pdf-viewer/toolbar/lib/styles/index.css';

// Use a ternary operator to make sure that the document object is defined
const portalRoot = typeof document !== `undefined` ? document.body : null

const PdfViewer = ({url, clickElement}) => {

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform = (slot) => ({
      ...slot,
      // These slots will be empty
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      SwitchTheme: () => <></>,
      SwitchThemeMenuItem: () => <></>,
      ShowPropertiesMenuItem: () => <></>,
      GoToNextPage: () => <></>,
      GoToPreviousPage: () => <></>,
      CurrentPageInput: () => <></>, 
      NumberOfPages: () => <></>,
      ShowSearchPopover: () => <></>          
  });

    const [show, setShow] = useState(false)

    const Image = ({element}) => {
        return (
            <a                     
            onClick={() => {
                setShow(true)
                document.body.classList.add("modal-is-open")
            }}>
            <img className={element.attribs.class} src={element.attribs.src} alt={element.attribs.alt}></img>
            </a>
        )
      }

    const Modal = ({url, activator}) => {

        const content = show && (
        <div className="pdf-modal-container active-modal">
          <div className="pdf-modal-container__overlay">
            <div className="pdf-modal-container__modal">
              <button
                className="pdf-modal-container__close"
                type="button"
                onClick={() => {
                  document.body.classList.remove("modal-is-open")
                  setTimeout(()=>setShow(false), 300)
                }}
              >
                <CloseIcon />
              </button>
              <div className="pdf-modal-container__body">
                <Worker workerUrl="https://compassus-pathways-app-general.s3.amazonaws.com/pdf.worker.min.js">
                    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                    <Viewer
                        fileUrl={url}
                        plugins={[
                            toolbarPluginInstance
                        ]}
                    />
                </Worker>
              </div>
            </div>
          </div>
        </div>
        )
      
        return (
          <>
            {activator({ setShow })}
            {portalRoot ? createPortal(content, document.body) : null}
          </>
        )
      }

    return (
        <div className="pdf-modal-container">
        <Modal
            url={url}
            activator={({ setShow }) => (
            clickElement.name === "img" ?
                <Image 
                    element={clickElement} 
                />
            :
            <button
                className="button button--pill"
                type="button"
                onClick={() => {
                    setShow(true)
                    document.body.classList.add("modal-is-open")
                }}
            >
                <span className="button__icon-text">{clickElement.data ? clickElement.data : "VIEW PDF"}</span>
            </button>
            )}
        />
        </div>
    );
};

export default PdfViewer;